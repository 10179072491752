import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import { authStore } from '@/stores/auth'
import { subtotalStore } from '@/stores/subtotal'
import { headerStore } from '@/stores/header'
import { Room } from '@/types/completion1/room'
import { Layout } from '@/types/completion1/layout'

const ls = new SecureLS({ isCompression: false })

export const completionOneStore = defineStore('completionOne', () => {
  const loading = ref<{ rooms:boolean, layouts:number[] }>({ rooms: false, layouts: [] })
  const saving = ref({ roomId: 0, layoutId: 0 })
  const phaseState = ref('loading')
  const phaseIsClosed = computed(() => ['closed', 'processed', 'expired'].includes(phaseState.value) || headerStore().ordersReceived.completion)
  const isInternalTestAccount = computed(() => ls.get('demoAccount').toLowerCase() === authStore().user.email.toLowerCase() || ls.get('oldDemoAccount').toLowerCase() === authStore().user.email.toLowerCase())

  const rooms = ref<Room[]>([])

  function getRooms () : void {
    loading.value.rooms = true
    axios.get(process.env.VUE_APP_API_URL + '/completion1/rooms').then((roomResponse) => {
      rooms.value = roomResponse.data

      rooms.value.forEach((room:Room, index:number) => {
        getLayouts(room.id, index)
        getSelectedLayout(room.id, index)
      })
    }).finally(() => {
      loading.value.rooms = false
    })
  }

  function getLayouts (roomId:number, roomIndex:number) : void {
    loading.value.layouts.push(roomId)
    axios.post(process.env.VUE_APP_API_URL + '/completion1/layouts', { rid: roomId }).then((layoutResponse) => {
      rooms.value[roomIndex].layouts = layoutResponse.data
      rooms.value[roomIndex].layouts.forEach((layout:Layout) => {
        if (layout.default) {
          rooms.value[roomIndex].default_layout = layout
        }
      })
    }).finally(() => {
      loading.value.layouts.splice(loading.value.layouts.indexOf(roomId), 1)
    })
  }

  function getSelectedLayout (roomId:number, roomIndex:number) : void {
    loading.value.layouts.push(roomId)
    axios.post(process.env.VUE_APP_API_URL + '/completion1/layout/selected', { rid: roomId }).then((selectedLayoutResponse) => {
      rooms.value[roomIndex].selected_layout = selectedLayoutResponse.data
    }).finally(() => {
      loading.value.layouts.splice(loading.value.layouts.indexOf(roomId), 1)
    })
  }

  function selectLayout (room:Room, roomIndex:number, layout:Layout) : void {
    saving.value.roomId = room.id
    saving.value.layoutId = layout.id
    axios.post(process.env.VUE_APP_API_URL + '/completion1/layout/store', {
      rid: room.id,
      lid: layout.id,
      lidDefault: room.default_layout.id
    }).then(() => {
      rooms.value[roomIndex].selected_layout = layout
      subtotalStore().getSubtotals()
    }).finally(() => {
      saving.value.roomId = 0
      saving.value.layoutId = 0
    })
  }

  function getPhaseState () : void {
    axios.get(process.env.VUE_APP_API_URL + '/phase/2/closed').then((r) => {
      phaseState.value = r.data.status
    })
  }

  return { loading, saving, phaseState, phaseIsClosed, isInternalTestAccount, rooms, getRooms, getLayouts, getSelectedLayout, selectLayout, getPhaseState }
})
